import { EmploymentResourceType } from "src/store/employments/actions";

import {
    AddressType,
    CatalogType,
    Employment,
    Field,
    FieldType,
    PhoneType,
    SelectValueType,
} from "src/types";

import {
    checkingForeignerPfae,
    checkingMethods,
    checkingMethodsForeignerAssalaried,
    checkingMethodsMexAssalaried,
    checkingMethodsMexPfae,
    currencyString,
    filingFrequencies,
    formatDate,
    paymentFrequencies,
    proofSeniorityTypesForeignerAssalaried,
    proofSeniorityTypesMexAssalaried,
    translateEnumAnswer
} from "src/helpers";

import _ from "lodash";
import { bancoppelCreditParticipantEmploymentFields } from "./bancoppelFields";

export interface CustomValuesProps {
    employmentName: string,
    salaryScheme: string,
    checkingMethod: string,
    active: boolean,
    predetermined: boolean,
    nationality: string
}

export function getEmploymentFields({
    values,
    catalogs,
    resourceType,
    isKredi,
}: {
    values: any,
    resourceType?: EmploymentResourceType,
    isKredi?: boolean,
    salaryScheme?: string,
    catalogs?: {
        salary_scheme?: CatalogType[]
        activity_type?: CatalogType[]
        income_source?: CatalogType[]
        economic_activity_sat?: CatalogType[]
        economic_activity_cnbv?: CatalogType[]
        profession?: CatalogType[]
    }
}) {
    const {
        salary_scheme,
        checking_method,
        active,
        nationality
    } = values

    const _proofSeniorityTypes = filterProofSeniorityTypes(nationality, checking_method, salary_scheme)

    const fields = resourceType === 'creditParticipant'
        ? isKredi
            ? [...creditParticipantEmploymentFields({ ...catalogs })]
            : [...bancoppelCreditParticipantEmploymentFields({ ...catalogs })]
        : [...employmentFields({
            nationality,
            proofSeniorityOptions: _proofSeniorityTypes,
            isKredi
        })]

    const index = fields.findIndex(f => f.key == 'ends_on');
    if (index > -1) {
        fields[index].disabled = active
    }

    if (resourceType === 'creditParticipant') {
        return fields
    }

    switch (salary_scheme) {
        case 'assalaried': {

            return [
                ...fields,
                ...assalariedFields(nationality, checking_method)
            ]
        }

        case 'freelance':
        case 'businessman':
            return [
                ...fields,
                ...businessmanFields(nationality, checking_method)
            ]

        case 'pensioner':
            return [
                ...fields,
                ...pensionerFields
            ]

        default:
            return fields
    }
}

// Apollo fields
let employmentFields = ({
    nationality,
    proofSeniorityOptions,
    isKredi
}: {
    nationality: string,
    proofSeniorityOptions: SelectValueType[],
    isKredi?: boolean
}): Field<keyof Employment>[] => {
    let inputs: Field<keyof Employment>[] = [
        {
            label: "Actividad económica",
            // @ts-ignore
            field_type: "salary_scheme",
            key: 'salary_scheme',
            options: filterSalarySchemes(nationality),
            row: 1,
            width: 6,
            sort_order: 2,
            required: true,
            config: {
                disableRenderRadio: true
            }
        },
        {
            label: "Antiguedad laboral",
            field_type: "select_field",
            key: 'proof_of_seniority_type',
            options: proofSeniorityOptions,
            row: 4,
            width: 6,
            sort_order: 0,
            hidden: proofSeniorityOptions.length === 0,
            required: proofSeniorityOptions.length > 0
        },
    ]

    if (isKredi) {
        inputs = inputs.concat([
            {
                label: "Lugar de comprobación de ingresos",
                // @ts-ignore
                field_type: "nationality",
                key: 'nationality',
                row: 1,
                width: 6,
                sort_order: 1,
                required: true,
                config: {
                    translated_field_type: "Lugar de comprobación de ingresos",
                    type: 'country_select'
                },
            },
            {
                label: "Ingreso bruto mensual",
                field_type: "currency",
                key: 'before_taxes_salary',
                row: 2,
                width: 6,
                required: true,
                config: {
                    currency_type: 'MXN',
                    payment_period: {
                        field: 'yearly_before_taxes_salary',
                        type: 'monthly'
                    }
                }
            },
            {
                label: "Ingreso bruto anual",
                field_type: "currency",
                key: 'yearly_before_taxes_salary',
                row: 2,
                width: 6,
                required: true,
                config: {
                    currency_type: 'MXN',
                    payment_period: {
                        field: 'before_taxes_salary',
                        type: 'annual'
                    }
                }
            },

        ])
    } else {
        inputs = inputs.concat([
            {
                label: "Ingreso neto mensual",
                field_type: "currency",
                key: 'net_salary',
                row: 2,
                width: 4,
                required: true,
                config: {
                    currency_type: 'MXN',
                    payment_period: {
                        field: 'yearly_net_salary',
                        type: 'monthly'
                    }
                }
            },
            // {
            //     label: "Ingreso neto anual",
            //     field_type: "currency",
            //     key: 'yearly_net_salary',
            //     row: 2,
            //     width: 4,
            //     required: true,
            //     config: {
            //         currency_type: 'MXN',
            //         payment_period: {
            //             field: 'net_salary',
            //             type: 'annual'
            //         }
            //     }
            // },
        ])
    }

    return inputs
}

// Kronos fields
let creditParticipantEmploymentFields = ({
    salary_scheme,
    activity_type,
    income_source,
    economic_activity_cnbv,
    economic_activity_sat,
    profession,
}: {
    salary_scheme?: CatalogType[]
    activity_type?: CatalogType[]
    income_source?: CatalogType[]
    economic_activity_sat?: CatalogType[]
    economic_activity_cnbv?: CatalogType[]
    profession?: CatalogType[]
}): Field[] => [
        {
            label: "Perfil económico",
            field_type: "select_field",
            key: 'salary_scheme',
            options: salary_scheme?.map(scheme => ({
                value: scheme?.external_id,
                caption: scheme?.description
            })),
            row: 1,
            width: 6,
            sort_order: 1,
            disabled: false,
            required: true,
            config: {
                disableRenderRadio: true,
                row: true,
            }
        },
        {
            label: "Fecha de inicio",
            field_type: "date",
            key: 'starts_on',
            row: 2,
            width: 3,
            sort_order: 2,
            config: {
                variant: "picker"
            },
        },
        {
            label: "Fecha final",
            field_type: "date",
            key: 'ends_on',
            sort_order: 3,
            config: {
                variant: "picker"
            },
            row: 2,
            width: 3
        },
        {
            label: "Empleo actual",
            field_type: "binary",
            key: 'predetermined',
            row: 2,
            width: 3,
            sort_order: 4,
            fieldContainerProps: {
                style: {
                    display: 'flex',
                    alignItems: 'center'
                }
            }
        },
        {
            label: "Ingreso bruto mensual",
            field_type: "currency",
            key: 'before_taxes_salary',
            row: 4,
            width: 6
        },
        {
            label: "Ingreso neto mensual",
            field_type: "currency",
            key: 'net_salary',
            row: 4,
            width: 6,
            required: true
        },
        {
            label: "Tipo de actividad",
            field_type: "select_field",
            key: 'activity_type',
            row: 5,
            width: 6,
            required: true,
            options: activity_type?.map(activity => ({
                value: activity?.external_id,
                caption: activity?.description
            })),
        },
        {
            label: "Fuente de ingresos",
            field_type: "select_field",
            key: 'income_source',
            row: 5,
            width: 6,
            required: true,
            options: income_source?.map(source => ({
                value: source?.external_id,
                caption: source?.description
            })),
        },
        {
            label: "Profesión",
            //@ts-ignore
            field_type: "autocomplete2",
            key: 'profession',
            row: 6,
            width: 6,
            required: true,
            options: profession?.map(profession => ({
                value: profession?.external_id,
                caption: profession?.name
            })),
        },
        {
            label: "Posición",
            field_type: "text_field",
            key: 'position',
            row: 6,
            width: 6,
            required: true
        },
        {
            label: "Actividad económica (SAT)",
            //@ts-ignore
            field_type: "autocomplete2",
            key: 'economic_activity_sat',
            row: 7,
            width: 6,
            required: true,
            options: economic_activity_sat?.map(activity => ({
                value: activity?.external_id,
                caption: activity?.description
            })),
        },
        {
            label: "Actividad económica (CNBV)",
            //@ts-ignore
            field_type: "autocomplete2",
            key: 'economic_activity_cnbv',
            row: 7,
            width: 6,
            required: true,
            options: economic_activity_cnbv?.map(activity => ({
                value: activity?.external_id,
                caption: activity?.description
            })),
        },
        {
            label: "Teléfono",
            field_type: "phone",
            key: 'default_phone',
            row: 8,
            width: 6,
            required: false,
        },
    ]

const assalariedFields = (nationality: string, checkingMethod: string) => {
    const _assalariedFields: any[] = [
        {
            label: "Método de comprobación",
            // @ts-ignore
            field_type: "checking_method",
            key: 'checking_method',
            options: nationality === 'Extranjero'
                ? checkingMethodsForeignerAssalaried
                : checkingMethodsMexAssalaried,
            row: 3,
            width: 6,
            sort_order: 1,
            required: true,
            config: {
                disableRenderRadio: true,
            }
        },
    ]

    if (checkingMethod !== 'tax_return') {
        _assalariedFields.push({
            label: "Frecuencia de pago",
            field_type: "select_field",
            key: 'payment_frequency',
            options: paymentFrequencies,
            config: {
                disableRenderRadio: true,
            },
            row: 3,
            width: 6,
            sort_order: 2,
        })
    }

    if (checkingMethod == 'tax_return') {
        _assalariedFields.push({
            label: "Tipo de declaración",
            field_type: "select_field",
            key: 'filing_frequency',
            options: checkingMethod === 'tax_return'
                ? filingFrequencies
                : [],
            config: {
                disableRenderRadio: true,
            },
            row: 3,
            width: 6,
            sort_order: 3,
        })
    }

    return _assalariedFields
}

const businessmanFields = (nationality: string, checkingMethod: string) => {
    const _businessmanFields: any[] = [
        {
            label: "Método de comprobación",
            field_type: "checking_method",
            key: 'checking_method',
            options: nationality === 'Extranjero'
                ? checkingForeignerPfae
                : checkingMethodsMexPfae,
            row: 3,
            width: 6,
            sort_order: 2,
            required: true,
            config: {
                disableRenderRadio: true,
            }
        },
    ]

    if (checkingMethod === 'tax_return') {
        _businessmanFields.push({
            label: "Tipo de declaración",
            field_type: "select_field",
            key: 'filing_frequency',
            options: checkingMethod === 'tax_return'
                ? filingFrequencies
                : [],
            config: {
                disableRenderRadio: true,
            },
            row: 3,
            width: 6,
            sort_order: 3,
        })
    }

    return _businessmanFields
}

const pensionerFields = [
    {
        label: "Frecuencia de pago",
        field_type: "select_field",
        key: 'payment_frequency',
        options: paymentFrequencies,
        config: {
            disableRenderRadio: true,
        },
        row: 3,
        width: 4,
        sort_order: 1,
    },
]

export function parseCheckingMethods(value?: string[]): SelectValueType[] {
    return checkingMethods.filter(cM => value?.includes(cM.value))
}

function filterProofSeniorityTypes(
    nationality: string,
    checkingMethod: string,
    salaryScheme: string
): SelectValueType[] {
    if (
        salaryScheme === 'assalaried' &&
        checkingMethod === 'paysheet_and_statement'
    ) {
        return nationality === 'Extranjero'
            ? proofSeniorityTypesForeignerAssalaried
            : proofSeniorityTypesMexAssalaried
    }
    return []
}

function filterSalarySchemes(nationality: string): SelectValueType[] {
    const salarySchemes = [
        { value: "assalaried", caption: "Asalariado / Asimilado a salarios" },
        { value: "freelance", caption: "Independiente / Actividad empresarial" },
    ]
    // @ts-ignore
    if (nationality === 'México' || nationality === 'Mexico') {
        salarySchemes.push({ value: "pensioner", caption: "Pensionado" })
    }

    return salarySchemes
}

export function translateSalaryScheme(value?: string): string {
    switch (value) {
        case 'assalaried':
            return 'Asalariado / Asimilado a salarios'
        case 'freelance':
            return 'Independiente / Actividad empresarial'
        case 'pensioner':
            return 'Pensionado'
        default:
            return value || ''
    }
}