import { useSelector } from "react-redux"
import { getPixelTrackingUrl } from "./helpers"

export function ApplicationTracking() {
    const organization = useSelector(state => state.organization)
    const application = useSelector(state => state.applications.application)

    if (!organization?.installed_apps?.pixel?.domain || !application.id)
        return null

    return (
        <img
            height={1}
            width={1}
            src={getPixelTrackingUrl(
                application,
                organization.installed_apps.pixel.domain,
                organization.installed_apps.pixel.path,
                organization.installed_apps.pixel.attribute
            )}
            style={{ display: 'none' }}
        />
    )
}