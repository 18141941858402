import { TableColumnType } from "src/components";
import { currencyString, translateEnumAnswer } from "src/helpers";
import { Employment } from "src/types";
import { translateSalaryScheme } from "../../Details/Form/fields";

export const getEmploymentColumns = (isKredi: boolean): TableColumnType<Employment>[] => isKredi ? [
    {
        label: 'Actividad económica',
        key: 'salary_scheme',
        render: value => translateSalaryScheme(value),
    },
    {
        label: 'Ingreso bruto mensual',
        key: 'before_taxes_salary',
        render: value => currencyString(value),
    },
    {
        label: 'Ingreso bruto anual',
        key: 'yearly_before_taxes_salary',
        render: value => currencyString(value),
    },
    {
        label: 'Lugar de ingreso',
        key: 'nationality',
    },
    {
        label: '',
        key: 'predetermined',
    },
] : [
    {
        label: 'Actividad económica',
        key: 'salary_scheme',
        render: value => translateSalaryScheme(value),
    },
    {
        label: 'Ingreso neto mensual',
        key: 'net_salary',
        render: value => currencyString(value),
    },
    // {
    //     label: 'Ingreso neto anual',
    //     key: 'yearly_net_salary',
    //     render: value => currencyString(value),
    // },
    {
        label: 'Lugar de ingreso',
        key: 'nationality',
    },
    {
        label: '',
        key: 'predetermined',
    },
]